<template>
  <div>
          <div style="margin-bottom:20px;">
            <v-container>
              <home-property-card :properties="recent_properties" title="Latest Properties" subtitle="This week" see_all="latest" ></home-property-card>

            </v-container>

            <v-divider></v-divider>
            <v-container>
              <home-promoted-card :properties="featured_properties" title="Promoted Properties" ></home-promoted-card>

            </v-container>
            <v-divider class="home"></v-divider>
            <!-- <v-container> -->
                <home-advert-card></home-advert-card>
            <!-- </v-container> -->

            <v-divider class="home"></v-divider>
      <!-- recommended -->
            <v-container>
              <home-property-card :properties="recommended_properties" title="Recommended" subtitle="Hand picked for you"
                see_all="recommended" ></home-property-card>
            </v-container>
            <v-divider ></v-divider>
            <v-container>
<!--              hot proper-->
              <home-property-card :properties="hot_properties" title="Hot Properties" subtitle="Irresistible deals" see_all="hot" ></home-property-card>
            </v-container>

      <seperator></seperator>
        <v-container>
          <by-location-card :properties="allstate" title="Browse By Location" subtitle see_all="location"></by-location-card>
        </v-container>

      <!-- <seperator></seperator> -->
    </div>

  

  </div>
  </template>
<script>
import { loadingMixin } from "@/mixins/Loading";
import {propertyMixin} from "@/mixins/Property";
export default {
  inject: ["Bus"],
  mixins: [loadingMixin,propertyMixin],
  data(){
    return{
      hide:false
    }
  },
  computed: {
    featured_properties() {
      return this.$store.state.property_module.home_featured_properties;
    },
    recent_properties() {
      return this.$store.state.property_module.home_recent_properties;
    },
    recommended_properties() {
      return this.$store.state.property_module.home_recommended_properties;
    },
    hot_properties(){
        return this.$store.state.property_module.home_hot_properties;
      },
    allstate() {
      return this.$store.state.general_module.all_states;
    }
  },
  methods: {
    fetchAllPropertiesMode() {
        this.$store.dispatch("listPropertiesAction",{type:'promoted',option:'promoted'});
        this.$store.dispatch("listPropertiesAction",{type:'latest',option:'latest'});
        this.$store.dispatch("listPropertiesAction",{type:'recommended',option:'recommended'});
        this.$store.dispatch("listPropertiesAction",{type:'hot',option:'hot'});
       this.$store.dispatch("getAllStateAction");
    }
  },
  created() {
      // this.fetchAllPropertiesMode();
  },
  mounted() {
    // this.fetchAllPropertiesMode();
  }
};
</script>
<style >
  .m_col{
    overflow-x:auto;
  }
  .v-application .title {
    font-family: Circular Std !important;
    font-size: 21px !important;
  }

  .title{
    /*margin-bottom: -10px;*/
    font-family: Circular Std;
    font-style: normal;
    font-weight: 500;

    line-height: 18px;
  }
  v-divider .home{
    margin:30px 0 10px 0 !important;
  }
</style>