<template>
    <div>
         <v-container >
                <v-row
          align-center
          justify="center"
          fill-height
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-0 justify-center mt-10" >
               <v-card-title class="font-weight-black">
                Welcome {{user.firstname}}
                </v-card-title>
                  <v-card-subtitle>
                    Enter the code sent to your mail
                    </v-card-subtitle>
                <v-spacer />
              
                  <v-form ref="verify_form" >
                 
                      <v-text-field outlined
                                    label="Code"
                                    type="text"
                                    ref="code"
                                    v-model="form.code"
                                    :rules="[rules.required, rules.counter, rules.number]"
                                    />
                    
                      <v-btn style="color:#fff !important;" height="48" type="submit" :loading="apiloading" :disabled="apiloading" block color="secondary" @click.prevent="verifyMycode()" >Verify</v-btn>
                  </v-form>
              </v-card-text>
              <v-card-actions class="justify-center">
                  <v-btn text color="secondary" style="text-transform: capitalize" :loading="apiloading" :disabled="apiloading" @click="retry()"> <span style="font-size:15px">Resend code</span></v-btn>
                  <v-spacer></v-spacer>
                    <v-btn text color="secondary" style="text-transform: capitalize" :loading="apiloading" :disabled="apiloading" @click="backToLogin()"> <span style="font-size:15px">Back to Login</span></v-btn>
                  <!-- <router-link :to="{name:'login'}" class="a-link">Back to Login</router-link> -->
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        </v-container>
    </div>
</template>
<script>
import { loadingMixin } from '../../mixins/Loading'
import toast from '../../Services/ToastService'
export default {
    inject:["Bus"],
    mixins:[loadingMixin],
    data() {
      return {
            rules: {
                required: value => !!value || 'Required.',
                counter: value => value.length <= 6 || 'Max 6 digits',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
                number:value=>{
                    const regre = /[0-9]|\./
                     return regre.test(value) || "Invalid number";
                 },
            },
            form:{
              code:''
            }
      }
    },
    computed: {
      user() {
        return this.$store.state.user_module.user
      }
    },
    methods: {
      retry() {
        this.$store.dispatch("resendCodeAction").then((res)=>{
          let m =  res.data.data;
          toast.success(m);
        });
      },
      verifyMycode(){
        if(!this.$refs.verify_form.validate()){
            return
        }
        this.$store.dispatch("verifyUserMailAction",this.form).then((res)=>{
            let user = res.data.data;
        })
      },
      backToLogin(){
        this.$store.dispatch("logoutAction");
      }
    },
    created(){
        this.Bus.$emit('title-change','Verify Phone')
    }
}
</script>